export function header() {
	const headerEl = document.querySelector('.main-header')
	const isTransparentHeader = headerEl.classList.contains('main-header--transparent')
	const announcementEl = document.querySelector('[data-announcement]')
	let headerTopOffset = 0

	function updateHeaderClass() {
		const scrollPosition = window.scrollY
		if (scrollPosition > 0 && headerTopOffset === 0) {
			headerEl.classList.replace('main-header--top', 'main-header--not-top')
		} else {
			headerEl.classList.replace('main-header--not-top', 'main-header--top')
		}
	}

	if (document.readyState === 'interactive') updateHeaderClass()

	window.addEventListener('scroll', updateHeaderClass)

	announcement()

	function announcement() {
		if (!announcementEl || !isTransparentHeader) return

		const heroSection = document.getElementById('main-content')
		let announcementHeight

		const resizeObserver = new ResizeObserver(entries => {
			for (let entry of entries) {
				announcementHeight = entry.target.offsetHeight
			}
			updateHeaderPosition()
			updateHeroSectionHeight()
		})

		const updateHeaderPosition = () => {
			const scrolled = window.scrollY
			headerTopOffset = Math.max(0, announcementHeight - scrolled)
			headerEl.style.top = `${headerTopOffset}px`
		}

		const updateHeroSectionHeight = () => {
			if (heroSection && heroSection.children.length > 0) {
				const firstElement = heroSection.children[0]

				if (firstElement.classList.contains('min-height-full')) {
					firstElement.style.minHeight = `calc(100vh - ${announcementHeight}px)`
				}
			}
		}

		resizeObserver.observe(announcementEl)
		window.addEventListener('scroll', updateHeaderPosition)
	}
}
