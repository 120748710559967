export function formFollowUpText() {
	document.addEventListener('DOMContentLoaded', function () {
		// Function to check if URL contains specific parameters
		function hasQueryParams(params) {
			const urlParams = new URLSearchParams(window.location.search)
			for (let param of params) {
				if (urlParams.has(param)) {
					return true
				}
			}
			return false
		}

		// List of expected parameters from the follow-up form
		const requiredParams = ['email']

		// Check if the element with the data-follow-up-message attribute exists
		const followUpMessage = document.querySelector('[data-follow-up-message]')
		if (followUpMessage && hasQueryParams(requiredParams)) {
			followUpMessage.style.display = 'block'
		}
	})
}
